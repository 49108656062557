
import { namespace, Getter } from "vuex-class";
import { Component, Mixins, Watch } from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import RegionalHandlerMixin from "@/mixins/RegionalHandlerMixin";
import { FilteredEvent, EventQueuePriorityColors } from "@/store/eventqueue/types";
import EventQueueAreaInfoIcon from "@/components/event-queue/EventQueueAreaInfoIcon.vue";
import { FeaturesList, UserPermissions } from "@/store/types";
import { get } from "lodash";
import organizationApi from "@/services/api.organizationSettings.ts";
import { PaginatedSearchQueryParams } from '@/store/types.ts'
import PaginatedSearch from '@/mixins/PaginatedSearch';

const Eventqueue = namespace("eventqueue");
const Subscription = namespace("subscription");
const SiteMonitor = namespace("siteMonitor");

@Component({
	components: {
		VuePerfectScrollbar,
		"event-queue-area-info-icon": EventQueueAreaInfoIcon
	}
})
export default class AlarmQueue extends Mixins(RegionalHandlerMixin, PaginatedSearch) {
	@Eventqueue.Getter getActiveEvents!: FilteredEvent[];
	@Eventqueue.Getter getCurrentSort: any;

	// Mutations for Bulk Alarm Handling data
	@Eventqueue.Mutation setAllAlarmsSelected: (allSelected: boolean) => void;
	@Eventqueue.Mutation setSelectedAlarms: (data: number[]) => void;
	@Eventqueue.Mutation addSelectedAlarm: (data: number) => void;
	@Eventqueue.Mutation removeSelectedAlarm: (data: number) => void;
	@SiteMonitor.Getter("getEventCloseShown") eventCloseShown!: boolean;

	@Eventqueue.Action setDisplayedEvent: any;

	@Eventqueue.Mutation setHoverEvent: any;
	@Eventqueue.Mutation setCurrentSort: any;
	@Eventqueue.Mutation setCurrentSortChanged: any;
	@Eventqueue.Mutation setPeekEventId: (eventId: number | null) => void;

	// Getters for Bulk Alarm Handling data
	@Eventqueue.Getter("getBulkHandleShown") bulkHandleShown!: boolean;
	@Eventqueue.Getter("getAllAlarmsSelected") allAlarmsSelected!: boolean;
	@Eventqueue.Getter("getSelectedAlarms") selectedAlarms: number[];
	@Eventqueue.Getter("getRefreshQueue") refreshQueue: boolean;
	@Eventqueue.Getter("getFilteredEventIds") filteredEventIds: number[];
	@Eventqueue.Mutation setRefreshQueue:any;

	@Subscription.State subscription: any;
	@Subscription.Getter isSubscriptionActive: boolean;

	// Getter and action for max events to display
	@Eventqueue.Getter getMaxEventsToShow: any;
	@Eventqueue.Action setMaxEventsToShow : any;

	// Getter and action for toggling the map' display.
	@Eventqueue.Action setHideMapFlag : any;
	@Eventqueue.Getter("getHideMapFlag") hideMapFlag: boolean;

	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getAlarmAgeWarningSeconds") alarmAgeWarningSeconds: number;
	@Getter getPermissions: UserPermissions;

	private eventQueuePriorityColors: EventQueuePriorityColors[] = [];
	private isLoading: boolean = false;
	private totalRecords: number = 0;
	private pageNumber: number = 1;

	public columns: any[] = [
		{
			title: "Started",
			sort: 1,
			isDefault: true,
			currentSort: 0,
			description: "How long ago the event began",
			reversed: true
		},
		{
			title: "Location",
			sort: 2,
			isDefault: false,
			currentSort: 0,
			description: "Area affected by alarm",
			reversed: false
		},
		{
			title: "Event",
			sort: 3,
			isDefault: false,
			currentSort: 0,
			description: "Alarm title",
			reversed: false
		},
		{
			title: "Priority",
			sort: 4,
			isDefault: true,
			currentSort: 0,
			description: "Event priority",
			reversed: false
		},
		{
			title: "Alarms",
			sort: 5,
			isDefault: false,
			currentSort: 0,
			description: "Number of alarms in event",
			reversed: false
		}
	];

	// Public

	public async created(): Promise<void> {
		this.setMaxEventsToShow(200);
		if (this.isPriorityColorsEnabled) {
			this.eventQueuePriorityColors = await this.retrieveEventQueuePriorityColors();
		}
	}

	public hoverEvent(event: FilteredEvent): void {
		this.setHoverEvent(event);
	}

	public unhoverEvent(event: FilteredEvent): void {
		this.setHoverEvent({});
	}

	public columnClass(column: any): string {
		let currentSort = this.getCurrentSort;

		if (currentSort.sort == 0 && column.isDefault) {
			return "";
		} else if (currentSort.sort == column.sort) {
			return currentSort.desc ? "sortDesc" : "sortAsc";
		}
	}

	public sortColumnClick(clickColumn: any): void {
		let { sort, reversed: defaultDesc, desc: existingDesc } = this.getCurrentSort;

		if (clickColumn.sort != sort) {
			this.setCurrentSort({
				sort: clickColumn.sort,
				desc: clickColumn.reversed,
				reversed: clickColumn.reversed
			});
		} else {
			if (existingDesc == defaultDesc) {
				this.setCurrentSort({
					sort: clickColumn.sort,
					desc: !existingDesc,
					reversed: clickColumn.reversed
				});
			} else {
				this.setCurrentSort({
					sort: 0,
					desc: false,
					reversed: false
				});
			}
		}

		this.setCurrentSortChanged(true);
	}

	public alarmIconClick(event): void {
		// Show map if hidden and toggle map feature is enabled.
		if(this.isToggleMapEnabled) {
			this.setHideMapFlag(false);
		}
		this.setDisplayedEvent(event);
	}

	public onScroll({ target: { scrollTop, clientHeight, scrollHeight }}): void {
		if (scrollTop + clientHeight >= scrollHeight) {
			this.setMaxEventsToShow(this.getMaxEventsToShow + 200);
		}
	}

	// Handles the toggling of a row select checkbox and set the AllAlarmsSelected in the state
	public alarmSelectedToggled(event): void {
		// Logic to set to maintain (local) state of each row and update the state of AllSelected in the store
		if (event.target.checked) {
			// Row Selected
			// Add the array of checked rows
			this.addSelectedAlarm(parseInt(event.target.value));

			// Need to check if checking this row now means allAlarmsSelected should now be true
			if (this.selectedAlarms.length == this.events.length) {
				this.setAllAlarmsSelected(true);
			}
		}
		else {
			// Row Deselected
			// remove from the array of checked rows
			this.removeSelectedAlarm(parseInt(event.target.value));

			// As this is row deselected all allAlarmsSelected should must be false
			this.setAllAlarmsSelected(false);
		}
	}

	public isAlarmSelected(eventID: number) : boolean {
		return this.selectedAlarms.includes(eventID);
	}

	public isAlarmOverAlarmAge(eventAge: number): boolean {
		return this.isAlarmAgeWarningEnabled && this.alarmAgeWarningSeconds > 0 && eventAge >= this.alarmAgeWarningSeconds
	}

	// Getters

	private peekEvent(eventId: number): void {
		this.setPeekEventId(eventId);
	}

	private get isPickUpOnNameEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "PickUpOnName"]);
	}

	private get isAlarmAgeWarningEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "AlarmAgeWarning"]);
	}

	private get isEventPeekEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventQueue", "Peek"], false);
	}

	private get isHideMapsEnabled(): boolean {
		return get(this.featuresList, ["HideMaps"], false);
	}

	public get events(): FilteredEvent[] {
		if (this.getActiveEvents == null) {
			return null;
		}
	
		return this.getActiveEvents.filter(q => q.eventTypeID == 1 && q.inProcessing == false && q.unParkAt == null).map(evt => {return {...evt, priorityStyle: this.retrievePriorityStyle(evt.priority)}});
	}

	private retrievePriorityStyle(priority: number): string
	{
		var filtered = this.eventQueuePriorityColors.filter(eq => eq.minPriority <= priority && eq.maxPriority >= priority);
		return filtered.length == 0 ? "" : `background-color: ${filtered[0].backgroundColor}; color: ${filtered[0].foregroundColor};`;
	}

	public get bulkHandleEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventQueue", "EndMultipleEvents"]);
	}

	public get bulkHandlePermission(): boolean {
		return this.getPermissions.canEndMultipleEvents;
	}

	public get eventBulkHandleAllowed(): boolean {
		return this.bulkHandleEnabled && this.bulkHandlePermission && this.bulkHandleShown;
	}

	// Feature flag for map display toggling functionality.
	private get isToggleMapEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "Maps", "ToggleEventQueue"]);
	}

	private get isPriorityColorsEnabled(): boolean {
		return get(this.featuresList, ["Alarms", "EventQueue", "PriorityColors"], false);
	}

	public async retrieveEventQueuePriorityColors(paginatedSearchQueryParams?: PaginatedSearchQueryParams): Promise<EventQueuePriorityColors[]> {
		if (!this.isPriorityColorsEnabled){
			return [];
		}

		try {
			this.isLoading = true;
			let PaginatedRequestMaxPageSize: PaginatedSearchQueryParams = {
				page: this.pageNumber,
				pageSize: 200
			}
			let request = this.generateNewPaginatedSearchRequest(PaginatedRequestMaxPageSize);
			let eventColorsWithPagination = await organizationApi.retrieveAlarmQueuePriorityColors(request);

			while (eventColorsWithPagination.data.length < eventColorsWithPagination.totalRecords) {
				this.pageNumber += 1;
				if (this.pageNumber > 3) {
					console.error(`Could not fetch remaining event queue priority colors. Retrieved ${eventColorsWithPagination.data.length} records out of ${eventColorsWithPagination.totalRecords}.`);
					break;
				}
				PaginatedRequestMaxPageSize.page = this.pageNumber;
				request = this.generateNewPaginatedSearchRequest(PaginatedRequestMaxPageSize);
				let eventColorsWithPaginationNewPage = await organizationApi.retrieveAlarmQueuePriorityColors(request);
				eventColorsWithPagination.data = [...eventColorsWithPagination.data, ...eventColorsWithPaginationNewPage.data];
			}
			
			this.totalRecords = eventColorsWithPagination.totalRecords;
			
			return eventColorsWithPagination.data;
		}
		catch (ex) {
			console.error("Unexpected error fetching priority colors: " + ex);
		}
		finally {
			this.isLoading = false;
		}
	}

	// Watchers

	@Watch("allAlarmsSelected")
	public onAllAlarmsSelected(selectAll:boolean): void {
		// Add all the arrray of alarms if the AllAlarms set to true in the store
		if (selectAll) {
			this.setSelectedAlarms(this.filteredEventIds);
		}
	}

	@Watch("events")
	public eventsChanged(): void {

		// if close modal is open ignore event changes.
		if (this.eventCloseShown){
			return;
		}

		// Clear selected events if all events selected and event is added or removed from the event queue
		if(this.allAlarmsSelected && (this.filteredEventIds.length != this.selectedAlarms.length || this.events.length == 0) ){
			this.setAllAlarmsSelected(false);
			this.setSelectedAlarms([]);
		}
    }

	@Watch("refreshQueue")
	public reloadQueue(): void {
		// clear the array of selected alarms
		this.setSelectedAlarms([]);
		this.setRefreshQueue(false);
	}
}
