
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AlarmQueue from "./AlarmQueue.vue";
import { axiosInstance } from "@/axios.instance";
import { Event } from "@/store/site-monitor/types";
import { FilteredEvent } from "@/store/eventqueue/types";
import ResetActionsCCure from "@/components/ResetActionsCCure.vue";

const Eventqueue = namespace("eventqueue");

@Component({
	components: {
		"alarm-queue": AlarmQueue,
		"reset-actions-ccure": ResetActionsCCure
	}
})
export default class AlarmQueueCCure extends Vue {
	@Eventqueue.Mutation setEventQueueGetOverride: any;

	public mounted() {
		this.setEventQueueGetOverride("/CCure/queue");
	}

	public destroyed() {
		this.setEventQueueGetOverride(null);
	}

	public async resetActions(eventQueue: any) {
		if (!eventQueue.resetting) {
			Vue.set(eventQueue, "resetting", true);

			try {
				var resetEndpoint = "";

				if (
					typeof eventQueue.regionID == "undefined" ||
					eventQueue.regionID == null ||
					eventQueue.regionID <= 0
				) {
					resetEndpoint = `/CCure/ResetAllActions/${eventQueue.eventID}`;
				} else {
					resetEndpoint = `/CCure/ResetAllActions/${eventQueue.eventID}/${eventQueue.regionID}`;
				}

				await axiosInstance.get(resetEndpoint);
			} catch (err) {
				console.error(`Couldn't reset all actions for event ${eventQueue.eventID}: ${err}`);

				// Set the resetting property back to false as the operation failed
				Vue.set(eventQueue, "resetting", false);

				this.$notify({
					type: "error",
					title: "Reset All Actions",
					text:
						"Unable to reset all actions - please try again later, or contact support if the problem persists"
				});
			}
		}
	}
}
